import axios from 'axios'
import { ACCESS_TOKEN, USER } from 'constants/auth'
import notifyApi from 'layout/notifyApi'

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL + '/v1/',
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem(ACCESS_TOKEN)
    if (token) {
      config.headers.common['Authorization'] = 'Bearer ' + token
    }

    return config
  },

  function (error) {
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  function (response) {
    return response.data
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem(ACCESS_TOKEN)
      localStorage.removeItem(USER)
      notifyApi.firebaseSignOut()
      window.location.replace('/')
    }

    return Promise.reject(error)
  }
)

export default axiosClient
