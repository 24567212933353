import axiosNotiClient from '../features/axiosNotiClient' 

const notifyApi = {
  getAll(params) {
    const url = '/crm/users/notifies'
    return axiosNotiClient.get(url, { params })
  },
  getCountNotifyUnread(){
    const url = '/crm/users/notifies/count-unread'
    return axiosNotiClient.get(url)
  },
  getCountNotifyUnseen(){
    const url = '/crm/users/notifies/total-new'
    return axiosNotiClient.get(url)
  },
  putSeenAllNotify(){
    const url = '/crm/users/notifies/total-new'
    return axiosNotiClient.put(url)
  },
  putReadAllNotify(){
    const url = '/crm/users/notifies/read-all'
    return axiosNotiClient.get(url)
  },
  putReadNotifyById(id){
    const url = '/crm/users/notifies/'+ id +'/read'
    return axiosNotiClient.put(url)
  },
  firebaseSignUp(token){
    const url = '/crm/users/firebase/sign-up'
    return axiosNotiClient.post(url,{token})
  },
  firebaseSignOut(){
    const url = '/crm/users/firebase/sign-out'
    return axiosNotiClient.put(url)
  }
}

export default notifyApi
