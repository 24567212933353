import axiosClient from '../axiosClient'

const authAPI = {
  login(data) {
    const url = '/dnc/users-login'
    return axiosClient.post(url, data)
  },
  
  logout() {
    const url = '/logout'
    return axiosClient.put(url)
  },
}

export default authAPI
