import { ACCESS_TOKEN } from 'constants/auth'
import React from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'

const PrivateRoute = ({ children, ...rest }) => {
  const location = useLocation()
  const token = localStorage.getItem(ACCESS_TOKEN)

  return token ? (
    <Route {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: location },
      }}
    />
  )
}

export default PrivateRoute
