import { createContext, useState } from 'react'

export const AuthRoleContext = createContext()

export const AuthRoleProvider = ({ children }) => {
  const [authRole, setAuthRole] = useState({})
  const [roleLoading, setRoleLoading] = useState(false)  
  const [roleError, setRoleError] = useState(false)  

  const roleList = authRole?.action_keys?.reduce((previous, current) => {
    previous[current] = true
    return previous
  }, {})

  return (
    <AuthRoleContext.Provider
      value={{
        authRole,
        setAuthRole,
        authActions: authRole?.action_keys || [],
        roleList: roleList || {},
        roleLoading,
        setRoleLoading,
        roleError,
        setRoleError,
      }}
    >
      {children}
    </AuthRoleContext.Provider>
  )
}
