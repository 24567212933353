import Loading from 'components/Loading'
import React, { lazy, Suspense } from 'react'
import { HashRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import { message } from 'antd'
import './App.css'
import { AuthRoleProvider } from 'context/AuthContext'

const DefaultLayout = lazy(() => import('./layout/DefaultLayout'))
const Login = lazy(() => import('./features/auth/containers/Login'))
message.config({
  maxCount: 1,
})
function App() {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <AuthRoleProvider>
          <Switch>
            <Route path="/login" component={Login} />
            <PrivateRoute path="/" component={DefaultLayout} />
          </Switch>
        </AuthRoleProvider>
      </Router>
    </Suspense>
  )
}

export default App
