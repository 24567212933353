import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ACCESS_TOKEN, USER } from 'constants/auth'
import authAPI from './authAPI'

export const login = createAsyncThunk('auth/login', async (payload) => {
  const data = await authAPI.login(payload)

  // save data to local storage
  localStorage.setItem(ACCESS_TOKEN, data.token)
  localStorage.setItem(USER, JSON.stringify(data))

  return data
})

export const logout = createAsyncThunk('auth/logout', async (payload) => {
  const data = await authAPI.logout(payload)

  // clear local storage
  localStorage.removeItem(ACCESS_TOKEN)
  localStorage.removeItem(USER)

  return data
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: JSON.parse(localStorage.getItem(USER)) || {},
  },
  reducers: {},
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      state.user = action.payload
    },

    [logout.fulfilled]: (state, action) => {},
  },
})

const { reducer } = authSlice
// export const {} = actions
export default reducer
